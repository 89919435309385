import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components';
import BackgroundImage from '../components/BackgroundImage';

class GetInvolvedPage extends React.Component {
  render(props) {

    const bgImage = this.props.data.file.childImageSharp.fluid;

    return (
      <Layout>
      <SEO title="Get involved with Snaphints - help us grow our photography community" image={bgImage} />


       <BackgroundImage imageData={bgImage} height="300px">
        <Overlay>
          <h1>Get involved</h1>
        </Overlay>
      </BackgroundImage>


      <Main>
      
        <p>At Snaphints we want to grow a strong community of photographers, helping each other to suceed and grow in their passion and knowledge.</p>

        <h2>How can you get involved?</h2>

        <p>Here are a few ways you can get involved:</p>

        <h3>Follow us on social media</h3>
        <p>You can simply follow us on our social media networks, we're on Facebook, instagram and twitter (all links in the site header & footer). You can also go a step further by liking and sharing our posts to spread the love.</p>


        <h3>Write tips</h3>
        <p>If you want to get more involved in Snaphints you can write up some tips that you have found useful and want to share with others. You can <a href="/submit-tip">submit your tips</a> here.
        You will also get an author profile on the site, where you can promote your own social & website links.</p> 



      </Main>
      
      </Layout>
    )
  }
}

export default GetInvolvedPage




const Banner = styled.section`
  height: 300px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 20%;
  margin-bottom: 40px;
`

const Overlay = styled.div`
  max-width: 800px;
  margin: auto; 
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: flex-end;
  padding-bottom: 40px;

  h1 {
    font-size: 30px;
  }

  @media(max-width: 768px) {
    padding-left: 20px;
  }
`;

const Container = styled.div`
  max-width: 800px;
   margin: auto; 
   display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  padding-bottom: 40px;

  h1 {
    font-size: 30px;
  }

  @media(max-width: 768px) {
    padding-left: 20px;
  }
`

const Main = styled.div`
  max-width: 800px;
  margin: 60px auto; 
  color: #fff;

  @media(max-width: 768px) {
    padding: 0 20px;
    margin-top: 0;
  }

  form {
    display: flex;
    flex-direction: column;

    input {
      margin-bottom: 20px;
      font-size: 16px;
      padding: 5px;

      &:focus {
        outline-color: #3c7db6;
      }
    }

    textarea {
      min-height: 100px;
    }

    button {
      margin-top: 20px;
      display: flex;
      align-self: flex-end;
      background: #3c7db6;
      border: 1px solid #3c7db6;
      padding: 10px 15px;
      margin-left: 20px;
      font-size: 16px;
      color: #fff;

      @media screen and (max-width: 479px) {
        width: 100%;
        text-align: center;
        display: block;
      }
    }
  }
`



export const query = graphql`
  query {
    file(relativePath: { eq: "banner-images/get-involved.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, fit: COVER, grayscale: true) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`